import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("video_player", ({
  uid,
  jwpMediaId,
  mp4,
  motionThumb,
  motionThumbSrc,
  customThumb,
  isExternal,
  autoPlay,
  mute,
  chromeless,
  cover,
  loop,
  pauseWhenOthersPlay,
  pauseWhenNotVisible,
  floatOnScroll,
  show_chapter_buttons,
}) => ({
  uid: uid,
  jwpMediaId: jwpMediaId,
  mp4: mp4,
  motionThumb: motionThumb,
  motionThumbSrc: motionThumbSrc,
  customThumb: customThumb,
  isExternal: isExternal,
  autoPlay: autoPlay,
  mute: mute,
  chromeless: chromeless,
  cover: cover,
  loop: loop,
  pauseWhenOthersPlay: pauseWhenOthersPlay,
  pauseWhenNotVisible: pauseWhenNotVisible,
  floatOnScroll: floatOnScroll,
  show_chapter_buttons: show_chapter_buttons,
  API_URL: `https://cdn.jwplayer.com/v2/media/${ jwpMediaId }?format=json`,
  player: null,
  source: null,
  init() {
    if (this.mp4) {
      this.player = this.wrapHtmlPlayer();
    } else {
      const opts = this.jwOptions();
      this.player = jwplayer(this.$refs.video).setup(opts);
    }
    this.registerPlayer();
    this.attachEvents();
  },
  isIpadApp () {
    // If it's not a Median app, return false immediately
    if (!/Median/i.test(navigator.userAgent)) return false;
    // If it IS a Median app AND the platform reports iPad, return true
    if (/iPad/.test(navigator.platform)) return true;
    // If it IS a Median app AND the we think it's an iPad Pro, return true
    if (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)) return true;
    // Otherwise return false
    return false;
  },
  registerPlayer() {
    // The reels script (js/alpine/ReelsPlayer) defines a callback
    // function stored under the UID in window.frop_players to be
    // run here once the JWPlayer has initialised.
    if (window.frop_players === undefined) window.frop_players = {};
    if (window.frop_players_pause === undefined) window.frop_players_pause = {};
    const cb = window.frop_players[this.uid];
    window.frop_players[this.uid] = this.player;
    if (typeof cb === "function") cb();
    if (this.pauseWhenOthersPlay) window.frop_players_pause[uid] = this.player;
  },
  wrapHtmlPlayer() {
    const p = {
      eventQueues: {}
    };
    p.on = (eventName, callBack) => {
      if (typeof p.eventQueues[eventName] === 'undefined') {
        p.eventQueues[eventName] = [];
      }
      p.eventQueues[eventName].push(callBack);
    }
    p.trigger = (eventName, data) => {
      if (typeof p.eventQueues[eventName] === 'undefined') {
        return;
      }
      p.eventQueues[eventName].forEach(e => {
        e(data);
      });
    }
    p.play = () => {
      p.trigger('play');
      this.$refs.html_video.play();
    }
    p.pause = () => {
      this.$refs.html_video.pause();
    }
    p.getMute = () => {
      return this.$refs.html_video.getAttribute('muted') === 'true';
    }
    p.setMute = (mute) => {
      if (typeof mute !== "boolean") {
        throw new Error('setMute accepts only boolean values');
      }
      p.trigger('mute', { 'mute': mute });
      this.$refs.html_video.setAttribute('muted', mute);
    }
    p.setVolume = (volume) => {
      if (typeof volume !== 'number') {
        throw new Error('setVolume accepts only integers');
      }
      if (volume < 0 || volume > 100) {
        throw new Error('setVolume accepts only integers in the range 0-100 inclusive');
      }
      volume = volume / 100;
      this.$refs.html_video.volume = volume;
    }
    p.seek = (time) => {
      this.$refs.html_video.currentTime = time;
    }
    return p;
  },
  jwOptions () {
    let opts = {
      autostart: this.autoPlay,
      mute: this.mute,
      controls: !this.chromeless,
      repeat: this.loop,
      stretching: cover ? 'fill' : 'uniform',
      autoPause: { viewability: false },
      preload: 'auto',
      floating: this.floatOnScroll ? { dismissible: true } : { mode: 'never' },
      responsive: false,
      displaytitle: false,
      displayHeading: false,
      displaydescription: false,
      show_chapter_buttons: this.show_chapter_buttons,
    }

    if (this.motionThumb && this.motionThumbSrc) this.source = this.motionThumbSrc;

    const baseUrl = 'https://cdn.jwplayer.com';

    if (this.customThumb) {
      if (this.isExternal || this.isIpadApp()) {
        opts.file = `${baseUrl}/videos/${ this.jwpMediaId }.mp4`;
      } else {
        opts.file = `${baseUrl}/manifests/${ this.jwpMediaId }.m3u8`;
      }
      opts.image = `${location.protocol}//${location.host}${this.customThumb}`;
    } else {
      if (this.isIpadApp()) {
        opts.image = `https://cdn.jwplayer.com/v2/media/${ this.jwpMediaId }/poster.jpg`;
        opts.file = `${baseUrl}/videos/${ this.jwpMediaId }.mp4`;
      } else {
        opts.playlist = `${baseUrl}/v2/media/${ this.jwpMediaId }`;
      }
    }
    return opts;
  },
  async loadMP4 () {
    if (this.source) return;
    const response = await fetch(this.API_URL);
    const width = this.$refs.video.offsetWidth;
    const json = await response.json();
    if (response.status === 200) {
      if (this.motionThumb && !this.motionThumbSrc) {
        const imageThumb = json.playlist[0].images.find(o => (o.width === 640)).src;
        this.source = imageThumb.replace('.jpg', '.mp4');
      } else {
        let availableMP4Sources = json.playlist[0].sources.filter(o => (o.type === 'video/mp4'));
        for (let i = 0; i < availableMP4Sources.length; i++) {
          let candidate = availableMP4Sources[i];
          if (candidate.width >= width || i == availableMP4Sources.length - 1) {
            this.source = candidate.file;
            break;
          }
        }
      }
    }
  },
  attachEvents () {
    this.player.on("play", this.play.bind(this));
  },
  play (evt) {
    Object.keys(window.frop_players_pause).forEach((playerId) => {
      let player = window.frop_players_pause[playerId];
      if (playerId !== this.uid && player.getState() == "playing") {
        player.pause();
      }
    });
  },
  intersectStart () {
    if (this.autoPlay) {
      this.player.play();
    }
  },
  intersectStop () {
    if (this.pauseWhenNotVisible) {
      this.player.pause();
    }
  }
}));




// import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

// Alpine.data('video_player', (uid, pause_when_others_play, jwoptions) => ({
//   uid: uid,
//   pause_when_others_play: pause_when_others_play,
//   playerid: `video_player_${uid}`,
//   player: null,
//   init () {
//     const player_element = document.getElementById(this.playerid);
//     this.player = jwplayer(player_element).setup(jwoptions);
//     this.registerPlayer();
//     this.attachEvents();
//   },
//   registerPlayer () {
//     // The reels script (js/alpine/ReelsPlayer) defines a callback
//     // function stored under the UID in window.frop_players to be
//     // run here once the JWPlayer has initialised.
//     if (window.frop_players === undefined) window.frop_players = {};
//     if (window.frop_players_pause === undefined) window.frop_players_pause = {};
//     const cb = window.frop_players[this.uid];
//     window.frop_players[this.uid] = this.player;
//     if (typeof cb === 'function') cb();
//     if (this.pause_when_others_play) window.frop_players_pause[this.uid] = this.player;
//   },
//   attachEvents () {
//     this.player.on('play', this.play.bind(this));
//   },
//   play (evt) {
//     Object.keys(window.frop_players_pause).forEach(uid => {
//       let player = window.frop_players_pause[uid];
//       if (uid !== this.uid && player.getState() == 'playing') {
//         player.pause();
//       }
//     });
//   }
// }));
